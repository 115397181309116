<template>
  <div ref="content">
    <div ref="myChart1">

    </div>
    <div ref="myChart2" style="width: 100%;">

    </div>
  </div>
</template>

<script>
import ApexChart from '@/components/charts/apex-charts/ApexChart.vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs
import moment from 'moment'
import htmlToPdfmake from 'html-to-pdfmake'
import numeral from 'numeral'
import { alertPopUp, setLoading } from '@/@core/utils/index'

export default {
  components: { ApexChart },
  props: {
    project: {
      default: () => {},
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      logo: 'img/name-app.png',
      chart: true,
      html: '',
      uri1: '',
      uri2: '',
    }
  },
  computed: {
    projectData() {
      return {
        budgets: this.project.budgets ? this.project.budgets : [],
        costs: this.project.resumencosts ? this.project.resumencosts : [],
        char1: this.project.char1 ? this.project.char1 : [],
        char2: this.project.char2 ? this.project.char2 : [],
        cooperators: this.project.cooperators ? this.project.cooperators : [],
        geographies: this.project.geographies ? this.project.geographies : [],
        lines: this.project.lines ? this.project.lines : [],
        populations: this.project.populations ? this.project.populations : [],
        resource_managers: this.project.resource_managers ? this.project.resource_managers : [],
        sectors: this.project.sectors ? this.project.sectors : [],
        stakeholders: this.project.stakeholders ? this.project.stakeholders : [],
        subsectors: this.project.subsectors ? this.project.subsectors : [],
        vicepresidency: this.project.vicepresidency ? this.project.vicepresidency : [],
      }
    },
    budgets() {
      const data = this.projectData.budgets.map(res => {
        let ele = [
          {
            text: `${res.budget_origin}`,
            alignment: 'left',
            margin: 4,
            fontSize: 10,
          },
          {
            text: `${res.budget_source}`,
            alignment: 'left',
            margin: 4,
            fontSize: 10,
          },
          {
            text: `$ ${numeral(res.cash_value).format('0,0.00')}`,
            alignment: 'right',
            margin: 4,
            fontSize: 10,
          },
          {
            text: `$ ${numeral(res.value_species).format('0,0.00')}`,
            alignment: 'right',
            margin: 4,
            fontSize: 10,
          },
          {
            text: `$ ${numeral(res.total).format('0,0.00')}`,
            alignment: 'right',
            margin: 4,
            fontSize: 10,
          },
        ]
        return ele
      })
      return data
    },
    costs() {
      const data = this.projectData.costs.map(res => {
        let ele = [
          {
            text: `${res.name_of}`,
            alignment: 'left',
            margin: 4,
            fontSize: 10,
          },
          {
            text: `$ ${numeral(res.executed_cost).format('0,0.00')}`,
            alignment: 'right',
            margin: 4,
            fontSize: 10,
          },
        ]
        return ele
      })
      return data
    },
    optionsPie1() {
      return {
        series: this.projectData.char1.map(item => {
          return item.budget
        }),
        chart: {
          width: 380,
          type: 'pie',
        },
        dataLabels: {
          enabled: true,
          offsetX: -20,
          style: {
            colors: ['#000'],
          },
          dropShadow: {
            enabled: false,
          },
        },
        legend: {
          formatter: function (val, opts) {
            return val + ' - $' + numeral(opts.w.globals.series[opts.seriesIndex]).format('0,0.0')
          },
          position: 'bottom',
          fontSize: '11px',
          fontWeight: 'bold',
        },
        title: {
          text: 'Tipo Financiación',
        },
        labels: this.projectData.char1.map(item => {
          return item.budget_source
        }),
      }
    },
    optionsPie2() {
      return {
        series: this.projectData.char2.map(item => {
          return item.budget
        }),
        chart: {
          width: '1200px',
          type: 'pie',
        },
        dataLabels: {
          enabled: true,
          offsetX: -20,
          style: {
            colors: ['#000'],
            fontSize: '23px',
            fontWeight: 'bold',
          },
          dropShadow: {
            enabled: false,
          },
        },
        legend: {
          formatter: function (val, opts) {
            return val + ' - $' + numeral(opts.w.globals.series[opts.seriesIndex]).format('0,0.0')
          },
          fontSize: '20px',
          fontWeight: 'bold',
          horizontalAlign: 'center',
          position: 'right',
        },
        title: {
          text: '% de aportes por entidad',
          align: 'left',
          style: {
            colors: ['#000'],
            fontSize: '29px',
            fontWeight: 'bold',
          },
        },
        labels: this.projectData.char2.map(item => {
          return item.budget_types
        }),
      }
    },
    total() {
      if (this.projectData.costs) {
        let val = 0
        this.projectData.costs.forEach(cost => {
          if (cost.executed_cost) val = val + cost.executed_cost
        })
        return val
      } else return 0
    },
  },
  methods: {
    getStatus(status) {
      if (status === 'ideation') return 'Ideación'
      if (status === 'formulation') return 'Formulación'
      if (status === 'committe') return 'Comité'
      return 'Revisar api'
    },
    async getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.setAttribute('crossOrigin', 'anonymous')
        img.src = url
        img.onload = () => {
          let canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height

          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)

          let dataURL = canvas.toDataURL('image/png')
          resolve(dataURL)
        }

        img.onerror = error => {
          console.log(error)
          reject(error)
        }
      })
    },
    async createPdf() {
      try {
        let docDefinition = {
          info: {
            title: `Proyecto-${this.project.project_code}`,
          },
          pageMargins: [20, 100, 20, 40],
          defaultStyle: {
            font: 'times',
          },
          styles: {
            'ql-align-justify': {
              'text-align': 'justify',
            },
          },
          header: [
            {
              columns: [
                {
                  image: await this.getBase64ImageFromURL(this.logo),
                  width: 235,
                  height: 30,
                  margin: [30, 30, 0, 0],
                },
                {
                  text: `INFORME PROYECTO`,
                  fontSize: 23,
                  alignment: 'center',
                  margin: [0, 35, 0, 0],
                  color: '#96242b',
                },
              ],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 40,
                  y1: 13,
                  x2: 595.28 - 40,
                  y2: 13,
                  lineWidth: 10,
                  lineColor: '#d3242b',
                  margin: [0, 0, 0, 10],
                },
              ],
            },
          ],

          footer: function (currentPage, pageCount) {
            return [
              {
                text: `Página ${currentPage.toString()} de ${pageCount}`,
                fontSize: 8,
                alignment: 'left',
                bold: true,
                margin: [20, 20, 0, -10],
              },
              {
                text: `${moment(new Date()).format('DD-MMM-YYYY')}`,
                fontSize: 8,
                alignment: 'right',
                bold: true,
                margin: [0, 20, 20, -10],
              },
            ]
          },
          content: [
            {
              layout: 'firstTable',
              table: {
                widths: [80, '*', 100, 120],
                margin: [20, 10, 0, 20],
                body: [
                  [
                    { text: 'Código', fillColor: '#cfd8dc', bold: true, margin: 7, alignment: 'left' },
                    { text: this.project.project_code, margin: 7, alignment: 'left' },
                    { text: 'Fecha Inicio', fillColor: '#cfd8dc', bold: true, margin: 7, alignment: 'left' },
                    { text: moment(this.project.date_entry).format('DD-MMM-YYYY'), margin: 7, alignment: 'left' },
                  ],
                  [
                    { text: 'Descripción', fillColor: '#cfd8dc', bold: true, margin: 7, alignment: 'left' },
                    { text: this.project.project_description, margin: 7, alignment: 'left' },
                    { text: 'Etapa Actual', fillColor: '#cfd8dc', bold: true, margin: 7, alignment: 'left' },
                    { text: this.project.project_status, margin: 7, alignment: 'left' },
                  ],
                  [
                    { text: 'Gestor', fillColor: '#cfd8dc', bold: true, margin: 7, alignment: 'left' },
                    { text: this.project.project_manager, margin: 7, alignment: 'left' },
                    { text: 'Duración', fillColor: '#cfd8dc', bold: true, margin: 7, alignment: 'left' },
                    { text: this.project.duration, margin: 7, alignment: 'left' },
                  ],
                  [
                    { text: 'Tipo', fillColor: '#cfd8dc', bold: true, margin: 7, alignment: 'left' },
                    { text: this.project.project_types, margin: 7, alignment: 'left' },
                    { text: 'Origen', fillColor: '#cfd8dc', bold: true, margin: 7, alignment: 'left' },
                    { text: this.project.origins, margin: 7, alignment: 'left' },
                  ],
                ],
              },
            },
            {
              text: `OBJETIVOS:`,
              fontSize: 14,
              alignment: 'left',
              italics: true,
              margin: [0, 20, 0, 5],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 2,
                  x2: 595.28 - 40,
                  y2: 2,
                  lineWidth: 1,
                  lineColor: '#d3242b',
                },
              ],
            },
            this.html,
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 2,
                  x2: 595.28 - 40,
                  y2: 2,
                  lineWidth: 1,
                  lineColor: '#d3242b',
                },
              ],
            },
            {
              text: `FORMULADORES DEL PROYECTO:`,
              fontSize: 12,
              bold: true,
              alignment: 'left',
              margin: [0, 20, 0, 0],
            },
            this.projectData.resource_managers.map(res => {
              let ele = {
                text: `${res.fullname}`,
                fontSize: 10,
                alignment: 'left',
                margin: [20, 5, 0, 0],
              }
              return ele
            }),
            {
              text: `MUNICIPIO / DEPARTAMENTO:`,
              fontSize: 12,
              bold: true,
              alignment: 'left',
              margin: [0, 15, 0, 0],
            },
            this.projectData.geographies.map(res => {
              let ele = {
                text: `${res.geography}`,
                fontSize: 10,
                alignment: 'left',
                margin: [20, 5, 0, 0],
              }
              return ele
            }),
            {
              text: `VICEPRESIDENCIA:`,
              fontSize: 12,
              bold: true,
              alignment: 'left',
              margin: [0, 15, 0, 0],
            },
            this.projectData.vicepresidency.map(res => {
              let ele = {
                text: `${res.name_of}`,
                fontSize: 10,
                alignment: 'left',
                margin: [20, 5, 0, 0],
              }
              return ele
            }),
            {
              text: `POBLACIÓN:`,
              fontSize: 12,
              bold: true,
              alignment: 'left',
              margin: [0, 15, 0, 0],
            },
            this.projectData.populations.map(res => {
              let ele = {
                text: `${res.name_of}`,
                fontSize: 10,
                alignment: 'left',
                margin: [20, 5, 0, 0],
              }
              return ele
            }),
            {
              text: `LÍNEA:`,
              fontSize: 12,
              bold: true,
              alignment: 'left',
              margin: [0, 15, 0, 0],
            },
            this.projectData.lines.map(res => {
              let ele = {
                text: `${res.category} - ${res.line}`,
                fontSize: 10,
                alignment: 'left',
                margin: [20, 5, 0, 0],
              }
              return ele
            }),
            {
              text: `SECTOR:`,
              fontSize: 12,
              bold: true,
              alignment: 'left',
              margin: [0, 15, 0, 0],
            },
            this.projectData.sectors.map(res => {
              if (res) {
                let ele = {
                  text: `${res.sector}`,
                  fontSize: 10,
                  alignment: 'left',
                  margin: [20, 5, 0, 0],
                }
                return ele
              }
            }),
            {
              text: `SUBSECTOR:`,
              fontSize: 12,
              bold: true,
              alignment: 'left',
              margin: [0, 15, 0, 0],
            },
            this.projectData.subsectors.map(res => {
              let ele = {
                text: `${res.Subsector}`,
                fontSize: 10,
                alignment: 'left',
                margin: [20, 5, 0, 0],
              }
              return ele
            }),
            {
              text: `COOPERANTES:`,
              fontSize: 12,
              bold: true,
              alignment: 'left',
              margin: [0, 15, 0, 0],
            },
            this.projectData.cooperators.map(res => {
              let ele = {
                text: `${res.cooperator}`,
                fontSize: 10,
                alignment: 'left',
                margin: [20, 5, 0, 0],
              }
              return ele
            }),
            {
              text: `ALIADOS:`,
              fontSize: 12,
              bold: true,
              alignment: 'left',
              margin: [0, 15, 0, 0],
            },
            this.projectData.stakeholders.map(res => {
              let ele = {
                text: `${res.stakeholder}`,
                fontSize: 10,
                alignment: 'left',
                margin: [20, 5, 0, 0],
              }
              return ele
            }),
            {
              text: `PRESUPUESTO:`,
              fontSize: 14,
              italics: true,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 2,
                  x2: 595.28 - 40,
                  y2: 2,
                  lineWidth: 1,
                  lineColor: '#d3242b',
                },
              ],
            },
            {
              layout: 'firstTable',
              margin: [0, 10, 0, 30],
              table: {
                widths: ['*', '*', '*', '*', '*'],
                headerRows: 1,
                body: [
                  [
                    { text: 'Origen', fillColor: '#cfd8dc', bold: true, margin: 5, alignment: 'center', fontSize: 11 },
                    {
                      text: 'Tipo Financiación',
                      fillColor: '#cfd8dc',
                      bold: true,
                      margin: 5,
                      alignment: 'center',
                      fontSize: 11,
                    },
                    {
                      text: 'Valor Efectivo',
                      fillColor: '#cfd8dc',
                      bold: true,
                      margin: 5,
                      alignment: 'center',
                      fontSize: 11,
                    },
                    {
                      text: 'Valor Especie',
                      fillColor: '#cfd8dc',
                      bold: true,
                      margin: 5,
                      alignment: 'center',
                      fontSize: 11,
                    },
                    {
                      text: 'Total Aporte',
                      fillColor: '#cfd8dc',
                      bold: true,
                      margin: 5,
                      alignment: 'center',
                      fontSize: 11,
                    },
                  ],
                  ...this.budgets,
                ],
              },
            },
            {
              image: this.uri1,
              alignment: 'center',
              margin: 30,
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 2,
                  x2: 595.28 - 40,
                  y2: 2,
                  lineWidth: 1,
                  lineColor: '#d3242b',
                },
              ],
            },
            {
              image: this.uri2,
              alignment: 'center',
              margin: 30,
            },
            {
              text: `COSTOS Y GASTOS:`,
              fontSize: 14,
              italics: true,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 2,
                  x2: 595.28 - 40,
                  y2: 2,
                  lineWidth: 1,
                  lineColor: '#d3242b',
                },
              ],
            },
            {
              layout: 'secondTable',
              margin: [0, 10, 0, 30],
              table: {
                widths: ['*', '*'],
                headerRows: 1,
                body: [
                  [
                    {
                      text: 'Descripción',
                      fillColor: '#cfd8dc',
                      bold: true,
                      margin: 5,
                      alignment: 'center',
                      fontSize: 11,
                    },
                    {
                      text: 'Valor Ejecutado',
                      fillColor: '#cfd8dc',
                      bold: true,
                      margin: 5,
                      alignment: 'center',
                      fontSize: 11,
                    },
                  ],
                  ...this.costs,
                  [
                    {
                      text: 'Total',
                      fillColor: '#cfd8dc',
                      bold: true,
                      margin: 10,
                      alignment: 'center',
                      fontSize: 12,
                    },
                    {
                      text: `$ ${numeral(this.total).format('0,0.00')}`,
                      bold: true,
                      margin: 10,
                      alignment: 'center',
                      fontSize: 12,
                    },
                  ],
                ],
              },
            },
          ],
        }

        const win = window.open('', '_blank')
        if (win) pdfMake.createPdf(docDefinition).open({}, win)
        else alertPopUp(win)
        setLoading(false)

        this.$nextTick(() => {
          this.$emit('download')
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    pdfMake.fonts = {
      times: {
        normal: 'times.ttf',
        bold: 'times-bold.ttf',
        italics: 'times-it.ttf',
        bolditalics: 'times.ttf',
      },
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
      },
    }
    pdfMake.tableLayouts = {
      firstTable: {
        hLineColor: function (i) {
          return '#aaa'
        },
        vLineColor: function (i) {
          return '#aaa'
        },
      },
      secondTable: {
        hLineColor: function (i) {
          return '#aaa'
        },
        vLineColor: function (i) {
          return '#aaa'
        },
      },
    }
    this.html = this.project.objective ? htmlToPdfmake(this.project.objective) : ''
    let um = this
    const elchart1 = new ApexCharts(this.$refs.myChart1, this.optionsPie1)
    elchart1.render().then(() => {
      setTimeout(() => {
        elchart1.dataURI().then(uri => {
          um.uri1 = uri.imgURI
          if (um.uri1 && um.uri2) {
            um.createPdf()
          }
        })
      }, 1000)
    })
    const elchart2 = new ApexCharts(this.$refs.myChart2, this.optionsPie2)
    elchart2.render().then(() => {
      setTimeout(() => {
        elchart2.dataURI({ scale: 0.4 }).then(uri => {
          um.uri2 = uri.imgURI
          if (um.uri1 && um.uri2) {
            um.createPdf()
          }
        })
      }, 1000)
    })
  },
}
</script>

<style scoped>
</style>
