<template>
  <v-dialog
    :value="value"
    max-width="750"
    persistent
  >
    <v-card class="user-card-detail pa-sm-10 pa-3">
      <v-row>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-row>
      <v-card-title class="user-edit-title justify-center text-h5">
        Asignación de encuesta y comité
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        {{ props.description }}
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form
          class="multi-col-validation"
          @submit.prevent="submit"
          ref="form"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-autocomplete
                v-model="committee"
                :items="committeeList"
                label="Lista de Comités"
                hide-details="auto"
                item-value="id"
                :rules="[validators.required]"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
            >
              <v-autocomplete
                v-model="survey"
                :items="surveyList"
                label="Lista de Encuestas"
                hide-details="auto"
                :rules="[validators.required]"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              v-if="props.from"
            >
              <v-autocomplete
                v-model="status"
                :items="selectStatusList.filter(ele => ele.type === 'committe')"
                label="Lista de Estados Comité"
                hide-details="auto"
                :rules="[validators.required]"
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :loading="loading"
              >
                Guardar
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="close"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { ref, reactive } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import useProjectsList from '@/views/projects/projects-list/useProjectsList'
import { validateForm } from '@/@core/utils'
import useProjectsNew from '@/views/projects/projects-new/useProjectsNew'
export default {
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
    from: {
      default: false,
      type: Boolean,
      required: false,
    },
    description: {
      default: '',
      type: String,
      required: true,
    },
    id: {
      default: 0,
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const committee = ref('')
    const status = ref('')
    const committeeList = ref([])
    const surveyList = ref([])
    const survey = ref('')
    const loading = ref(false)
    const form = ref(null)
    const project = reactive({})
    const { updateProject } = useProjectsList()
    const { fetchProjectStatus, selectStatusList } = useProjectsNew()

    const fetchCommittees = () => {
      store
        .dispatch('committees/fetchCommittees')
        .then(response => {
          const data = ref([])
          if (response) data.value = JSON.parse(response[0].listcommittees)['committees']
          committeeList.value = data.value.map(ele => {
            return {
              text: ele.code + ' - ' + ele.description,
              value: ele.id,
              ...ele,
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
    const fetchSurveys = () => {
      store
        .dispatch('surveys/fetchSurveys')
        .then(response => {
          const data = ref([])
          if (response) data.value = JSON.parse(response[0].listsurveys)['surveys']
          surveyList.value = data.value.map(ele => {
            return {
              text: ele.code + ' - ' + ele.description,
              value: ele.id,
              ...ele,
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    }

    fetchCommittees()
    fetchSurveys()
    fetchProjectStatus()
    committee.value = ''
    status.value = ''
    survey.value = ''

    const close = () => {
      emit('input', false)
      if (form.value) form.value.reset()
    }

    const submit = async () => {
      try {
        const isFormValid = validateForm(form)
        if (!isFormValid) return
        loading.value = true
        let users = committeeList.value.find(comm => comm.value === committee.value).users.map(ele => ele.id)
        if (props.from) project['project_status_id'] = status.value
        project['id'] = props.id
        project['user_ids'] = users
        project['structure_survey'] = surveyList.value.find(sur => sur.value === survey.value).structure_survey
        await updateProject(project)
        loading.value = false
        emit('updated')
        emit('input', false)
      } catch (error) {
        console.log(error)
        loading.value = false
      }
    }

    return {
      close,
      committee,
      committeeList,
      form,
      loading,
      props,
      selectStatusList,
      status,
      submit,
      survey,
      surveyList,
      icons: {
        mdiClose,
      },
      validators: {
        required,
      },
    }
  },
}
</script>

