<template>
  <v-container fluid ma-0 pa-0>
    <v-row class="mb-1">
      <v-col
        v-for="(total, index) in projectTotalLocal"
        :key="total.total + String(index)"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveProjectTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveProjectTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveProjectTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveProjectTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-card>
          <v-card-title>
            Expense Ratio
          </v-card-title>
          <v-card-subtitle>
            Spending on various categories
          </v-card-subtitle>
          <v-card-text>
            <chart-expense-ratio></chart-expense-ratio>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-card>
          <v-card-title>
            <span>Data Science</span>
            <v-spacer></v-spacer>
            <span class="subtitle-2">
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiCalendarBlankOutline }}
              </v-icon>
              <span>2019-05-01 to 2019-05-10</span>
            </span>
          </v-card-title>
          <v-card-text>
            <chart-data-science></chart-data-science>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-card>
          <v-card-title>
            Expense Ratio
          </v-card-title>
          <v-card-subtitle>
            Spending on various categories
          </v-card-subtitle>
          <v-card-text>
            <chart-expense-ratio></chart-expense-ratio>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="mt-4"></v-divider>
    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0 pl-0">
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-1 me-3"
            @click="pushNew"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Nuevo</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-1"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>
        <!-- filter -->
        <v-col cols="12">
          <filter-by v-model="newItems" :filterFields="filterFields" :filterLists="filterLists"></filter-by>
        </v-col>

      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="newItems"
        :loading="loading"
        :options.sync="options"
        fixed-header
      >
        <template #[`item.description`]="{item}" >
          <td>
            {{ item.description }}
          </td>
        </template>

        <template #[`item.project_manager`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="30"
            >
              <v-img
                v-if="item.avatar"
                :src="require(`@/assets/images/avatars/${item.avatar}`)"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.project_manager) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-1 text--primary font-weight-semibold text-truncate ">
                {{ item.project_manager }}
            </div>
          </div>
        </template>

        <template #[`item.date_entry`]="{item}" >
          <td style="min-width: 100px;">
            {{ dating(item.date_entry) }}
          </td>
        </template>

        <template #[`item.date_end`]="{item}">
          <td style="min-width: 100px;">
            {{ item.date_end ? dating(item.date_end) : ''}}
          </td>
        </template>

        <template #[`item.budget`]="{item}">
          <td style="min-width: 150px;">
            {{ item.budget | currency }}
          </td>
        </template>

        <template #[`item.progress`]="{item}">
          {{ item.progress }}%
        </template>

        <template #[`item.effort`]="{item}">
          {{ item.effort }}%
        </template>

        <template #[`item.execution`]="{item}">
          {{ item.execution }}%
        </template>

        <template #[`item.status`]="{item}">
          <div class="d-flex align-center" style="min-width: 200px;">
            <v-avatar
              size="35"
              :color="resolveStatusVariant(item.project_status)"
              :class="`me-1`"
            >
              <img
                :style="'width:20px; height:20px;'"
                :src="require(`@/assets/images/my-images/${resolveStatusIcon(item.project_status)}`)"
              />
            </v-avatar>
            <span class="text-capitalize">{{ resolveStatusText(item.project_status) }}</span>
          </div>
        </template>

        <!-- <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <v-menu
              bottom
              left
              :close-on-content-click="!loadingPdf"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="setOne(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ actionOptions[0].icon }}
                  </v-icon>
                  <span>{{ actionOptions[0].title }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="getReport(item.id)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                    v-if="!loadingPdf"
                  >
                    {{ actionOptions[1].icon }}
                  </v-icon>
                  <v-progress-circular
                    v-else
                    :size="18"
                    :width="3"
                    class="me-2"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  <span>{{ actionOptions[1].title }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ actionOptions[2].icon }}
                  </v-icon>
                  <span>{{ actionOptions[2].title }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="setCommittee(item)"
                v-if="item.status !== 'Comite evaluativo' && item.status !== 'Formulacion de proyectos'"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ actionOptions[3].icon }}
                  </v-icon>
                  <span>{{ actionOptions[3].title }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          </div>
        </template>
 -->
        <template #[`item.actions`]="{item}">
            <td class="d-flex justify-center">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="setOne(item)"
                  >
                    <v-icon
                      size="18"
                      color="success"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="getReport(item.id)"
                  >
                    <v-icon
                      size="18"
                      color="#ff0000"
                    >
                      {{ icons.mdiFilePdfBox }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Informe</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="getReport(item.id)"
                  >
                    <v-icon
                      size="18"
                      color="info"
                    >
                      {{ icons.mdiTextBoxCheck }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Tareas</span>
              </v-tooltip>
              <v-tooltip bottom :disabled="!(item.project_status !== 'committe' && item.project_status !== 'formulation')">
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="setCommittee(item)"
                    :disabled="!(item.project_status !== 'committe' && item.project_status !== 'formulation')"
                  >
                    <v-icon
                      size="18"
                      color="#9C27B0"
                    >
                      {{ icons.mdiAccountGroup }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Comité</span>
              </v-tooltip>
            </td>
        </template>

      </v-data-table>
      <dialog-assign
        v-model="dialogAssign"
        v-if="dialogAssign"
        :description="description"
        :id="id"
        @updated="updated"
        :from="fromList"
      ></dialog-assign>
    </v-card>
      <report
        v-if="downloadPdf && Object.keys(project).length > 0"
        :project="project"
        :download="downloadPdf = false"
    ></report>
  </v-container>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { avatarText, dating } from '@core/utils/filter'
import {
  mdiAccountGroup,
  mdiCalendarBlankOutline,
  mdiExportVariant,
  mdiPlus,
  mdiDotsVertical,
  mdiPencilOutline,
  mdiFileChartOutline,
  mdiFilePdfBox,
  mdiTextBoxCheck,
} from '@mdi/js'
import { setLoading, useRouter } from '@core/utils'
import FilterBy from '@/components/widgets/filter/FilterBy.vue'
import DialogAssign from '@/components/widgets/dialog/DialogAssign.vue'
import chartExpenseRatio from '@/components/charts/apex-charts/ApexChartExpenseRatio.vue'
import chartDataScience from '@/components/charts/apex-charts/ApexChartDataScience.vue'
import useProjectsList from './useProjectsList'
import Report from './Report'

export default {
  components: {
    chartDataScience,
    chartExpenseRatio,
    DialogAssign,
    FilterBy,
    Report,
  },
  setup(props) {
    const { router } = useRouter()
    const newItems = ref([])
    const dialogAssign = ref(false)
    const fromList = ref(false)
    const loading = ref(false)
    const loadingPdf = ref(false)
    const downloadPdf = ref(false)
    const description = ref('')
    const project = ref({})
    const id = ref(0)

    const {
      fetchProjectList,
      fetchProjectReport,
      projectList,
      projectTotalLocal,
      filterFields,
      filterLists,
      options,
      resolveProjectTotalIcon,
      resolveStatusIcon,
      resolveStatusText,
      resolveStatusVariant,
      tableColumns,
    } = useProjectsList()

    const pushNew = () => {
      router.push('/proyectos/nuevo')
    }

    fromList.value = false

    const setCommittee = item => {
      description.value = item.description
      id.value = item.id
      fromList.value = true
      dialogAssign.value = true
    }

    const getReport = async id => {
      try {
        setLoading(true)
        const data = await fetchProjectReport(id)
        project.value = data
        downloadPdf.value = true
      } catch (error) {
        console.log(error)
        downloadPdf.value = false
        setLoading(false)
      }
    }

    const setOne = item => {
      router.push({ name: 'projects-id', params: { id: item.id, status: item.project_status } })
    }

    fetchProjectList()

    const updated = () => {
      fetchProjectList()
    }

    const actionOptions = [
      { title: 'Editar', icon: mdiPencilOutline },
      { title: 'Informe', icon: mdiFileChartOutline },
      { title: 'Tareas', icon: mdiTextBoxCheck },
      { title: 'Comité', icon: mdiAccountGroup },
    ]

    watch(
      projectList,
      val => {
        if (val) newItems.value = val
      },
      {
        deep: true,
      },
      {
        immediate: true,
      },
    )

    return {
      actionOptions,
      avatarText,
      dating,
      description,
      dialogAssign,
      downloadPdf,
      filterFields,
      filterLists,
      fromList,
      getReport,
      id,
      loading,
      loadingPdf,
      newItems,
      options,
      project,
      projectTotalLocal,
      pushNew,
      resolveStatusIcon,
      resolveProjectTotalIcon,
      resolveStatusText,
      resolveStatusVariant,
      setCommittee,
      setOne,
      tableColumns,
      updated,

      icons: {
        mdiAccountGroup,
        mdiCalendarBlankOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFilePdfBox,
        mdiPencilOutline,
        mdiPlus,
        mdiTextBoxCheck,
      },
    }
  },
}
</script>

<style scoped>
</style>
